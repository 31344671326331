<template>
  <div>
    <label-add
      :is-sidebar-active.sync="isAddNewLabelSidebarActive"
      :is-readonly="isAddNewLabelSidebarInDisplayMode"
      :value.sync="activeRecord"
    />

    <!-- Filters -->
    <!--    <label-list-filters-->
    <!--      :label-type-filter.sync="searchFilter.labelType"-->
    <!--      :label-type-options="labelTypeOptions"-->
    <!--    />-->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0 container-card"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col
            offset="12"
            cols="12"
            md="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <div
                style="position: absolute; left: 28px; top: 7px;"
                @click="refetchData"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Perform Search'"
                  size="18"
                  icon="SearchIcon"
                  class="mr-1"
                />
              </div>
              <b-form-input
                v-model="searchQuery"
                style="padding-left: 40px !important;"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="refetchData"
              />
              <div
                v-if="searchQuery.length !== 0"
                style="position: absolute; top: 7px; right: 158px"
                @click="clearSearch"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Clear Search'"
                  icon="XIcon"
                  size="18"
                />
              </div>
              <b-button
                variant="primary"
                @click="editRecord(null)"
              >
                <span class="text-nowrap">Add Label</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refLabelListTable"
        class="position-relative"
        :items="labels.nodes"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :no-local-sorting="true"
        :busy="isLoading"
        show-empty
        hover
        empty-text="No matching records found"
        @row-clicked="redirectToProcessPageWithLabelFilterApplied"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-1">
            <b-spinner
              type="grow"
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>

        <!-- Column: Number Of Processes -->
        <template
          #cell(processes)="data"
        >
          {{ data.item.processes.length }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="action-buttons">
            <b-button
              :id="`row-${data.item.id}-view-processes-icon`"
              variant="flat-warning"
              class="btn-icon rounded-circle"
              :href="getViewTasksRedirectUrl(data.item)"
              target="_blank"
            >
              <feather-icon
                icon="ToolIcon"
                size="16"
              />
              <b-tooltip
                title="View Related Processes"
                class="cursor-pointer"
                :target="`row-${data.item.id}-view-processes-icon`"
              />
            </b-button>

            <b-button
              :id="`row-${data.item.id}-edit-icon`"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="editRecord(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
              />
            </b-button>
            <b-tooltip
              title="Edit"
              class="cursor-pointer"
              :target="`row-${data.item.id}-edit-icon`"
            />

            <b-button
              :id="`row-${data.item.id}-delete-icon`"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteRecord(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
            </b-button>
            <b-tooltip
              title="Delete"
              class="cursor-pointer"
              :target="`row-${data.item.id}-delete-icon`"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ paginationMeta.from }} to {{ paginationMeta.to }} of {{ paginationMeta.of }} entries</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="5"
            sm="5"
            class="d-flex align-items-center justify-content-center"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="labels.totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

          <!-- Per Page -->
          <b-col
            cols="4"
            sm="4"
            class="d-flex align-items-center justify-content-end"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              append-to-body
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BTooltip,
  BSpinner, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { debounce } from 'vue-debounce'
import { mapState, mapActions } from 'vuex'
import { createPopper } from '@popperjs/core'
import LabelAdd from './LabelAdd.vue'

export default {
  components: {
    // LabelListFilters,
    LabelAdd,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BTooltip,
    BSpinner,

    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      tableColumns: [
        { key: 'label', tdClass: 'height-50-px', sortable: true },
        { key: 'processes', label: 'Number Of Processes', sortable: true },
        { key: 'actions' },
      ],
      isAddNewLabelSidebarActive: false,
      isAddNewLabelSidebarInDisplayMode: false,
      activeRecord: null,

      /* searchFilter: {
        labelType: null,
      }, */
      perPageOptions: [10, 25, 50, 100],
      perPage: 10,
      currentPage: 1,
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
    }
  },
  computed: {
    ...mapState('label', ['labels', 'isLoading']),
    paginationMeta() {
      return {
        from:
          this.perPage * (this.currentPage - 1)
          + (this.labels.nodes.length ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + this.labels.nodes.length,
        of: this.labels.totalCount,
      }
    },
    offsetAndLimitAndSortByAndIsDescAndAssociatedEntitiesAndIsSortDirDesc() {
      return `${this.perPage}|${this.currentPage}|${this.sortBy}|${this.isDesc}|${this.associatedEntities}|${this.isSortDirDesc}`
    },
    queryParams() {
      return {
        // labelType: this.searchFilter.labelType,
        q: this.searchQuery,
        offset: this.perPage * (this.currentPage - 1),
        limit: this.perPage,
        sortBy: this.sortBy,
        isDesc: this.isSortDirDesc,
      }
    },
  },
  watch: {
    offsetAndLimitAndSortByAndIsDescAndAssociatedEntitiesAndIsSortDirDesc: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  created() {
    this.getLabels(this.queryParams)
  },
  methods: {
    ...mapActions('label', ['getLabels']),
    refetchData: debounce(function cb() {
      this.getLabels(this.queryParams)
    }, '300ms'),
    editRecord(activeRecord) {
      this.activeRecord = activeRecord
      this.isAddNewLabelSidebarActive = true
      this.isAddNewLabelSidebarInDisplayMode = false
    },
    displayRecord(activeRecord) {
      this.editRecord(activeRecord)
      this.isAddNewLabelSidebarInDisplayMode = true
    },
    deleteRecord(activeRecord) {
      if (activeRecord.processes.length === 0) {
        this.$bvModal
          .msgBoxConfirm('Are you sure that you want to delete this label?', {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Delete',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.$store.dispatch('label/removeLabel', activeRecord.id)
            }
          })
      } else {
        this.$bvModal.msgBoxOk(`This label is being used by ${activeRecord.processes.length} processe(s).\n A label in active use cannot be deleted. \n`
            + 'To delete this label, first remove this label from the related processes.', {
          title: 'Warning',
          bodyClass: 'msg-box-body',
          size: 'md',
          okVariant: 'outline-secondary',
          okTitle: 'Cancel',
          hideHeaderClose: false,
          centered: true,
        })
      }
    },

    getViewTasksRedirectUrl(label) {
      return `processes?q=label=${label.label}&qd=labels=${label.id}`
    },

    redirectToProcessPageWithLabelFilterApplied(label) {
      this.$router.push(this.getViewTasksRedirectUrl(label))
    },

    // search related methods
    clearSearch() {
      this.searchQuery = ''
      this.refetchData()
    },

    // with popper
    withPopper(dropdownList, component, { width }) {
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.action-buttons {
  margin-left: -10px;

  button {
    margin-right: 4px;
  }
}
</style>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
.height-50-px{
  height: 50px;
}

.msg-box-body{
  white-space: pre-line;
  line-height: 28px;
}
</style>
